<script setup lang="ts">
import pro1 from '~/assets/pro1.svg';
import pro2 from '~/assets/pro2.svg';
import pro3 from '~/assets/pro3.svg';

const pros = [
  {
    icon: pro1,
    title: 'Wyspecjalizowane\nserwisy blisko Ciebie',
    subtitle:
      'Znajdź serwis specjalizujący się\nw urządzeniach konkretnego typu i marce.',
  },
  {
    icon: pro2,
    title: 'Bezpłatne\nzlecenie naprawy',
    subtitle:
      'Korzystanie z portalu jest dla\nużytkowników całkowicie bezpłatne.',
  },
  {
    icon: pro3,
    title: 'Wygodna\nnaprawa door2door',
    subtitle:
      'Zleć naprawę uszkodzonego sprzętu\nz odbiorem i dostawą przez kuriera.',
  },
];
</script>

<template>
  <div class="container">
    <div class="items-wrapper">
      <div
        class="pros-item"
        v-for="(item, index) in pros"
        :class="{ border: index < pros.length - 1 }"
      >
        <img width="40px" height="40px" :src="item.icon" :alt="item.icon" />
        <h3 style="white-space: pre-wrap;">{{ item.title }}</h3>
        <p style="white-space: pre-wrap;">{{ item.subtitle }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.border {
  border-right: 1px solid #dee1e3;
}

.container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 27px;
  margin: 0 auto;
  width: fit-content;
}

.items-wrapper {
  padding: 40px 20px 40px 20px;
  background-color: #f3f6f6;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pros-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 329px;
  padding: 0 20px;

  h3 {
    padding-top: 20px;
    color: #11335b;
  }

  p {
    color: #798491;
    font-family: 'Clarity-City';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    padding-top: 12px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    margin: 0 auto;
    padding: 20 36px;
    min-width: none;
  }

  .items-wrapper {
    flex-direction: column;
    justify-content: center;
  }

  .border {
    border-right: none;
    border-bottom: 1px solid #dee1e3;
  }

  .pros-item {
    padding: 20px 0;
  }
}

@media screen and (max-width: 768px) and (min-width: 601px) {
  .container {
    margin: 0 20px;
    min-width: none;
  }
}
</style>
