const homePageCitiesMap = {
    'Białystok': 'bialystok',
    'Gdańsk': 'gdansk',
    'Kraków': 'krakow',
    'Poznań': 'poznan',
    'Rzeszów': 'rzeszow',
    'Szczecin': 'szczecin',
    'Warszawa': 'warszawa',
    'Wrocław': 'wroclaw',
}

export default homePageCitiesMap;
